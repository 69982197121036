import React from "react";

export default class MochahubLogo extends React.Component {
  render() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.8679 38.4434H48.8207V47.4056H43.8679V38.4434Z"
          fill={this.props.fill}
        />
        <path
          d="M58.0189 25.7076H62.9717V47.4057H58.0189V25.7076Z"
          fill={this.props.fill}
        />
        <path
          d="M69.5755 40.3302H74.5283V47.4057H69.5755V40.3302Z"
          fill={this.props.fill}
        />
        <path
          d="M81.8396 30.1887H86.7924V47.4057H81.8396V30.1887Z"
          fill={this.props.fill}
        />
        <path
          d="M98.0302 96.4455C103.637 96.3005 108.969 93.9863 112.905 89.9899C117.022 85.8731 119.174 80.3571 119.174 74.546C119.174 63.3783 111.084 54.1099 99.7632 52.8715V52.3585H30.1887V84.7788C30.1887 101.951 43.2618 115.802 59.6757 119.34H25V124.292H75H125V119.34H71.0262C83.3708 117.217 93.8307 108.395 98.0302 96.4455ZM99.7641 84.7788V57.895C108.491 59.1028 114.201 66.0769 114.201 74.4988C114.232 78.9521 112.477 83.2323 109.328 86.3816C106.612 89.0858 103.054 90.783 99.2434 91.1929C99.6141 89.0755 99.7887 86.9283 99.7641 84.7788ZM34.9057 84.7788V57.0755H94.5755V84.7788C94.5755 87.5035 94.434 90.1981 93.7106 92.7858C90.1415 105.542 78.1132 114.453 64.8528 114.453C48.4906 114.453 34.9057 101.141 34.9057 84.7788Z"
          fill={this.props.fill}
        />
        <path
          d="M40.566 65.3302H45.5188V86.7924H40.566V65.3302Z"
          fill={this.props.fill}
        />
      </svg>
    );
  }
}
// export default MochahubLogo;
